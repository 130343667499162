import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { sendEmail } from "utils/email";
import toast, { Toaster } from 'react-hot-toast';

import validation from "utils/validation";

const Contact = ({ contentModuleId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const data = useStaticQuery(graphql`
    query {
      allContentfulLayoutContact {
        edges {
          node {
            id
            heading
            description {
              description
            }
            image {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutContact.edges.find(
    edge => edge.node.id === contentModuleId
  );

  useEffect(() => {
    validation.init();
  }, []);

  const handleFormSubmission = e => {
    e.preventDefault(); // dont need this
    // should check for validation
    toast.promise(
        sendEmail({ name, email, message }),
         {
           loading: 'Sending...',
           success: <b>Thanks!  You'll hear from us soon.</b>,
           error: <b>Something happened. Try again.</b>,
         }
       ).then(c => resetForm()) // should check for response
  };

  const resetForm = () => {
    document.getElementById("contact_form").reset();
  };

  return (
    <section id="contact" className="contact container section mx-auto ">
        <Toaster/>
      <div className="contact__content">
        <h2
          className="section__title"
          data-sal="fade"
          data-sal-easing="ease-in-cubic"
        >
          {content.node.heading}
        </h2>
        <p
          className="mb-4 w-full md:w-3/4"
          data-sal="slide-up"
          data-sal-easing="ease-in-cubic"
        >
          {content.node.description.description}
        </p>
        <form
          id="contact_form"
          className="w-full md:w-3/4"
          noValidate
          data-sal="slide-up"
          data-sal-easing="ease-in-cubic"
          data-sal-delay="100"
        >
          <div className="input-group mb-2">
            <label htmlFor="name">Name</label>
            <input
              onChange={e => setName(e.target.value)}
              type="text"
              id="name"
              className="input"
              name="name"
            />
          </div>
          <div className="input-group mb-2">
            <label htmlFor="email">Email</label>
            <input
              onChange={e => setEmail(e.target.value)}
              type="email"
              id="email"
              className="input"
              name="email"
            />
          </div>
          <div className="input-group">
            <label htmlFor="message">Message</label>
            <textarea
              onChange={e => setMessage(e.target.value)}
              id="message"
              className="h-20"
              name="message"
            ></textarea>
          </div>
          <button
            onClick={handleFormSubmission}
            className="btn btn--primary mt-8"
          >
            Send
          </button>
        </form>
      </div>
      <div className="contact__image">
        <div
          className="mx-auto"
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-duration="500"
        >
          <div className="contact__image-wrap">
            <Img fluid={content.node.image.fluid} alt="Contact" />
          </div>
        </div>
      </div>
    </section>
  );
};

Contact.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default Contact;
